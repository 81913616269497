import * as types from "../../constants";

const initialFetchDataState = {
  loading: true,
  OrderData: [],
  OrderAddResponse: [],
  OrdersList: [],
  clientOrders: [],
  Order: {},
  message_error: "",
  TotalOrders: "",
  receivedResponse: false,
  isOrderCreated: false,
  isOrderUpdated: false,
  isOrderDeleted: false,
  isOrdersListFetched: false,
  QrcodeGenerated: false,
  isClientOrdersListFetched: false,
  isFilesUploaded: "",
  isTotalOrdersFetched: false,
  isOrderStatusUpdated: false,
  isOrderCancelled: false,
  isOrderFetched: false,
  isOrderFileDeleted: false,
  statusCountData: [],
  isStatusCountFetched: false,
  employeeStatusCountData: [],
  isEmployeeStatusCountFetched: false,
  isCaseOwnerUpdated: false,
  isDocumentCreated: false,
  isDocumentUpdated: false,
};

export default function OrdersReducer(state = initialFetchDataState, action) {
  switch (action.type) {
    case types.UPDATE_ORDER_STATUS_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        isOrderStatusUpdated: true,
      };
      break;

    case types.UPDATE_ORDER_STATUS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: 200,
        isOrderStatusUpdated: false,
      };
      break;

    case types.UPDATE_ORDER_STATUS_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.UPDATE_ORDER_PO_NUMBER_FULFILLED:
      return {
        ...state,
        loading: false,
        receivedResponse: 200,
        isOrderStatusUpdated: true, // or create a specific flag for PO number if necessary
      };

    case types.UPDATE_ORDER_PO_NUMBER_PENDING:
      return {
        ...state,
        loading: true,
        receivedResponse: 200,
        isOrderStatusUpdated: false,
      };

    case types.UPDATE_ORDER_PO_NUMBER_REJECTED:
      return {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };

    case types.CREATE_DOCUMENT_PENDING:
      return {
        ...state,
        loading: true,
        isDocumentCreated: false,
      };

    case types.CREATE_DOCUMENT_FULFILLED:
      return {
        ...state,
        loading: false,
        isDocumentCreated: true,
      };

    case types.CREATE_DOCUMENT_REJECTED:
      return {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };

    case types.UPDATE_DOCUMENT_PENDING:
      return {
        ...state,
        loading: true,
        isDocumentUpdated: false,
      };

    case types.UPDATE_DOCUMENT_FULFILLED:
      return {
        ...state,
        loading: false,
        isDocumentUpdated: true,
      };

    case types.UPDATE_DOCUMENT_REJECTED:
      return {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
    case types.UPDATE_CASE_OWNER_FULFILLED:
      return {
        ...state,
        loading: false,
        isCaseOwnerUpdated: true,
      };

    case types.UPDATE_CASE_OWNER_PENDING:
      return {
        ...state,
        loading: true,
        isCaseOwnerUpdated: false,
      };

    case types.UPDATE_CASE_OWNER_REJECTED:
      return {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
    case types.GET_ORDER_STATUS_COUNT_FULFILLED:
      return {
        ...state,
        loading: false,
        statusCountData: action.payload,
        isStatusCountFetched: true,
      };

    case types.GET_ORDER_STATUS_COUNT_REJECTED:
      return {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };

    case types.GET_EMPLOYEE_ORDER_STATUS_COUNT_FULFILLED:
      return {
        ...state,
        loading: false,
        employeeStatusCountData: action.payload,
        isEmployeeStatusCountFetched: true,
      };

    case types.GET_EMPLOYEE_ORDER_STATUS_COUNT_REJECTED:
      return {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };

    case types.ADD_NEW_ORDER_FULFILLED:
      state = {
        ...state,
        loading: false,
        OrderAddResponse: action.payload,
        receivedResponse: 200,
        isOrderCreated: true,
      };
      break;

    case types.ADD_NEW_ORDER_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isOrderCreated: false,
      };
      break;

    case types.ADD_NEW_ORDER_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.GET_ORDER_STATUS_COUNT_PENDING:
      return {
        ...state,
        loading: true,
        isStatusCountFetched: false,
      };

    case types.GET_TOTAL_ORDERS_FULFILLED:
      state = {
        ...state,
        loading: false,
        TotalOrders: action.payload,
        receivedResponse: 200,
        isTotalOrdersFetched: true,
      };
      break;

    case types.GET_TOTAL_ORDERS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isTotalOrdersFetched: false,
      };
      break;

    case types.GET_TOTAL_ORDERS_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.GET_ORDER_LIST_FULFILLED:
      state = {
        ...state,
        loading: false,
        OrdersList: action.payload,
        receivedResponse: 200,
        isOrdersListFetched: true,
      };
      break;

    case types.GET_ORDER_LIST_PENDING:
      state = {
        ...state,
        loading: true,
        isOrdersListFetched: false,
        receivedResponse: false,
      };
      break;

    case types.GET_ORDER_LIST_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
        isOrdersListFetched: false,
      };
      break;

    case types.GET_UPDATED_ORDER_LIST_FULFILLED:
      state = {
        ...state,
        loading: false,
        OrdersList: action.payload,
        receivedResponse: 200,
        // isOrdersListFetched: true,
      };
      break;

    case types.GET_UPDATED_ORDER_LIST_PENDING:
      state = {
        ...state,
        loading: true,
        // isOrdersListFetched: false,
        // receivedResponse: false,
      };
      break;

    case types.GET_UPDATED_ORDER_LIST_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
        // isOrdersListFetched: false,
      };
      break;

    case types.GET_JOB_ORDER_BY_ID_FULFILLED:
      state = {
        ...state,
        loading: false,
        Order: action.payload,
        receivedResponse: 200,
        isOrderFetched: true,
      };
      break;

    case types.GET_JOB_ORDER_BY_ID_PENDING:
      state = {
        ...state,
        loading: true,
        isOrderFetched: false,
        receivedResponse: false,
      };
      break;

    case types.GET_JOB_ORDER_BY_ID_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
        isOrderFetched: false,
      };
      break;

    case types.DELETE_JOB_ORDER_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        isOrderDeleted: true,
      };
      break;

    case types.DELETE_JOB_ORDER_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: 200,
        isOrderDeleted: false,
      };
      break;

    case types.DELETE_JOB_ORDER_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.DELETE_JOB_ORDER_BY_ID_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        isOrderFileDeleted: true,
      };
      break;

    case types.DELETE_JOB_ORDER_BY__PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: 200,
        isOrderFileDeleted: false,
      };
      break;

    case types.DELETE_JOB_ORDER_BY__REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.CANCEL_ORDER_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        isOrderCancelled: true,
      };
      break;

    case types.CANCEL_ORDER_PENDING:
      state = {
        ...state,
        loading: true,
        isOrderCancelled: false,
      };
      break;

    case types.CANCEL_ORDER_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.UPDATE_JOB_ORDER_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        isOrderUpdated: true,
      };
      break;

    case types.UPDATE_JOB_ORDER_PENDING:
      state = {
        ...state,
        loading: true,
        isOrderUpdated: false,
      };
      break;

    case types.UPDATE_JOB_ORDER_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.RESET_STATE:
      state = {
        ...state,
        loading: false,
        isOrderCreated: false,
      };
      break;

    case types.LINK_LEAD_TO_ORDER_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
      };
      break;

    case types.LINK_LEAD_TO_ORDER_PENDING:
      state = {
        ...state,
        loading: true,
      };
      break;

    case types.LINK_LEAD_TO_ORDER_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.ADD_FILES_TO_ORDER_FULFILLED:
      state = {
        ...state,
        loading: false,
        isFilesUploaded: true,
        receivedResponse: 200,
      };
      break;

    case types.ADD_FILES_TO_ORDER_PENDING:
      state = {
        ...state,
        loading: true,
      };
      break;

    case types.ADD_FILES_TO_ORDER_REJECTED:
      state = {
        ...state,
        loading: false,
        isFilesUploaded: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;
    case types.GENERATE_QR_CODE_FULFILLED:
      state = {
        ...state,
        loading: false,
        QrcodeGenerated: true,
        receivedResponse: 200,
      };
      break;

    case types.GENERATE_QR_CODE_PENDING:
      state = {
        ...state,
        loading: true,
      };
      break;

    case types.GENERATE_QR_CODE_REJECTED:
      state = {
        ...state,
        loading: false,
        QrcodeGenerated: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;
    case types.DOWNLOAD_JOB_FILES_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
      };
      break;

    case types.DOWNLOAD_JOB_FILES_PENDING:
      state = {
        ...state,
        loading: true,
      };
      break;

    case types.DOWNLOAD_JOB_FILES_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;
    default:
      state = {
        ...state,
      };
  }
  return state;
}
