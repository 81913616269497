import axios from "axios";
import { serverAddress } from "../../constants.js";

const utils = {
  getAllMRN: (params) => {
    let url =
      serverAddress +
      `/mrn/all?page=${params.page}&limit=${params.limit}&search=${
        params.search ? params.search : ""
      }`;
    return axios.get(url, {
      headers: { Authorization: `Bearer ${params.token}` },
    });
  },
  createNewMRN: (params) => {
    if (params !== undefined) {
      //code to call server api
      let url = serverAddress + "/mrn/create";
      return axios.post(url, params, {
        headers: { Authorization: `Bearer ${params.token}` },
      });
    }
  },
  getMRNDetails: (params) => {
    if (params !== undefined) {
      let url = serverAddress + `/mrn/${params.id}`;
      return axios.get(url, {
        headers: { Authorization: `Bearer ${params.token}` },
      });
    }
  },
  updateMRN: (params) => {
    if (params.id !== null) {
      let url = serverAddress + "/mrn/update";
      return axios.put(url, params, {
        headers: { Authorization: `Bearer ${params.token}` },
      });
    }
  },

  getMRNByPo: (props) => {
    console.log("show po_no:", props.po_no);
    let url = `${serverAddress}/mrn/getmrnbypo/${props.po_no}`;
    return axios.get(url, {
      headers: { Authorization: `Bearer ${props.token}` },
    });
  },
};

export default utils;
