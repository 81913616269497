import * as types from "../../constants";

const initialFetchDataState = {
  loading: true,
  MrnData: [],
  MrnAddResponse: [],
  MrnList: [],
  MrnDatabyPo: [],
  message_error: "",
  receivedResponse: false,
  isMrnCreated: false,
  isMrnListFetched: false,
  isMrnDetailsFetched: false,
  isMrnUpdated: false,
};

export default function MrnReducer(state = initialFetchDataState, action) {
  switch (action.type) {
    case types.GET_MRN_BY_PO_PENDING:
      return {
        ...state,
        loading: true,
        isMrnDetailsFetched: false,
      };

    case types.GET_MRN_BY_PO_FULFILLED:
      console.log("MRN Data fetched:", action.payload);
      return {
        ...state,
        loading: false,
        MrnDatabyPo: action.payload,
        receivedResponse: 200,
        isMrnDetailsFetched: true,
      };

    case types.GET_MRN_BY_PO_REJECTED:
      return {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };

    case types.CREATE_MRN_FULFILLED:
      state = {
        ...state,
        loading: false,
        MrnAddResponse: action.payload,
        receivedResponse: 200,
        isMrnCreated: true,
      };
      break;

    case types.CREATE_MRN_PENDING:
      state = {
        ...state,
        loading: true,
        isMrnCreated: false,
      };
      break;

    case types.CREATE_MRN_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.GET_MRN_LIST_FULFILLED:
      state = {
        ...state,
        loading: false,
        MrnList: action.payload,
        receivedResponse: 200,
        isMrnListFetched: true,
      };
      break;

    case types.GET_MRN_LIST_PENDING:
      state = {
        ...state,
        loading: true,
        isMrnListFetched: false,
        receivedResponse: false,
      };
      break;

    case types.GET_MRN_LIST_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;
    case types.GET_UPDATED_MRN_LIST_FULFILLED:
      state = {
        ...state,
        loading: false,
        MrnList: action.payload,
        receivedResponse: 200,
        // isMrnListFetched: true,
      };
      break;

    case types.GET_UPDATED_MRN_LIST_PENDING:
      state = {
        ...state,
        loading: true,
        // isMrnListFetched: false,
        // receivedResponse: false,
      };
      break;

    case types.GET_UPDATED_MRN_LIST_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;
    case types.GET_MRN_DETAILS_FULFILLED:
      state = {
        ...state,
        loading: false,
        MrnData: action.payload,
        receivedResponse: 200,
        isMrnDetailsFetched: true,
      };
      break;

    case types.GET_MRN_DETAILS_PENDING:
      state = {
        ...state,
        loading: true,
        isMrnDetailsFetched: false,
        receivedResponse: false,
      };
      break;

    case types.GET_MRN_DETAILS_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;
    case types.UPDATE_MRN_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        isMrnUpdated: true,
      };
      break;

    case types.UPDATE_MRN_PENDING:
      state = {
        ...state,
        loading: true,
        isMrnUpdated: false,
        receivedResponse: false,
      };
      break;

    case types.UPDATE_MRN_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.RESET_MRN:
      state = {
        ...state,
        isMrnCreated: false,
        isMrnUpdated: false,
      };
      break;

    default:
      state = {
        ...state,
      };
  }
  return state;
}
